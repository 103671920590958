import { isEmpty } from 'ramda'

//REACT
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// CONTEXT
import { AllPagesContext } from 'contexts/AllPagesContext'

//MUI MATERIAL
import Button from '@mui/material/Button'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'

//MUI ICON MATERIAL
import IconLock from '@mui/icons-material/Lock'
import IconPerson from '@mui/icons-material/Person'
import IconVisibility from '@mui/icons-material/Visibility'
import IconVisibilityOff from '@mui/icons-material/VisibilityOff'

// SERVICES
import { postLoginUser } from 'services/user'

//STYLES
import signInUseStyles from './signInUseStyles'

// UTILS
import { didSuccessfullyCallTheApi } from 'utilities/validation'
import { setUserProfileToLocalStorage } from 'utilities/localStorage'

const SignIn = () => {
  const classes = signInUseStyles()
  const { setAuth } = useContext(AllPagesContext)
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const email = data.get('email')
    const password = data.get('password')
    const isEmptyEmail = isEmpty(email)
    const isEmptyPassword = isEmpty(password)
    if(isEmptyEmail || isEmptyPassword){
      setEmailError(isEmptyEmail)
      setPasswordError(isEmptyPassword)
    }
    else {
      const abortController = new AbortController()
  
      const resultLoginUser = await postLoginUser(
        abortController.signal,
        {
          login: email,
          password,
          locale: 'en',
        }
      )

      if (didSuccessfullyCallTheApi(resultLoginUser.status)) {
        const userProfileObject = {
          email,
          accessToken: resultLoginUser?.data?.hash,
          hash: resultLoginUser?.data.hash,
          // refreshToken: resultLoginUser?.data?.refreshToken, // NO REFRESH TOKEN YET
        }

        setUserProfileToLocalStorage(userProfileObject)
        setAuth(userProfileObject)

        navigate('/')
      }
      else {

      }

      abortController.abort()
    }
  }

  return (
    <Stack component='form' noValidate onSubmit={handleSubmit} className={classes.formStyle}>
      <Stack direction='row' display={'flex'} alignItems={'flex-end'} >
        <Stack color={'grey'} marginRight={1}>
          <IconPerson />
        </Stack>
        <Stack width={'100%'}>
          <FormControl variant='standard'>
            <InputLabel sx={{position: 'relative', marginBottom: -2.2}}>Email</InputLabel>
            <Input
              required
              error={emailError}
              helperText={emailError ? 'Please fill this field.' : ''}
              fullWidth
              id='email'
              name='email'
              variant='standard'
              onChange={event => setEmail(event.target.value)}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack marginBottom={3} />
      <Stack direction='row' display={'flex'} alignItems={'flex-end'} >
        <Stack color={'grey'} marginRight={1}>
          <IconLock />
        </Stack>
        <Stack width={'100%'}>
          <FormControl variant='standard'>
            <InputLabel sx={{position: 'relative', marginBottom: -2.2}}>Password</InputLabel>
            <Input
              required
              error={passwordError}
              helperText={passwordError? 'Please fill this field.' : ''}
              fullWidth
              id='password'
              name='password'
              variant='standard'
              onChange={event => setPassword(event.target.value)}
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <IconVisibilityOff /> : <IconVisibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack marginBottom={2} marginTop={3}>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          disabled={ email === '' || password === '' }>
          Login
        </Button>
      </Stack>
    </Stack>
  )
}

export default SignIn