export const checkListItems = {
  trips: [
    {
      label: 'Hide empty tabs',
      value: 1,
      name: 'hide_empty_tabs'
    },
    {
      label: 'Show seconds',
      value: 2,
      tooltipText: 'Time format including seconds',
      name: 'show_seconds',
    },
    {
      label: 'Display summary',
      value: 3,
      name: 'include_summary_sheet'
    },
    {
      label: 'Display only summary',
      value: 4,
      name: 'include_summary_sheet_only'
    }
  ],
  stops: [
    {
      label: 'Hide empty tabs',
      value: 1,
      name: 'hide_empty_tabs'
    },
    {
      label: 'Show seconds',
      value: 2,
      tooltipText: 'Time format including seconds',
      name: 'show_seconds',
    },
    {
      label: 'Show coordinates',
      value: 3,
      name: 'show_coordinates'
    },
    {
      label: 'Use smart filter',
      value: 4 ,
      name: 'filter'
    }
  ],
  tripsAndStops: [
    {
      label: 'Hide empty tabs',
      value: 1,
      name: 'hide_empty_tabs'
    },
    {
      label: 'Show seconds',
      value: 2,
      tooltipText: 'Time format including seconds',
      name: 'show_seconds'
    },
  ],
}
  
export const advancedChecklistItems = {
  trips: [
    {
      label: 'Divide by stops',
      value: 1,
      name: 'split',
    },
    {
      label: 'Show stop duration',
      value: 2,
      name: 'show_idle_duration'
    },
    {
      label: 'Show coordinates',
      value: 3,
      name: 'show_coordinates'
    },
    {
      label: 'Use smart filter',
      value: 4,
      name: 'filter'
    },
    {
      label: 'Group by driver',
      value: 5,
      name: 'group_by_driver'
    }
  ],
  tripsAndStops: [
    {
      label: 'Use smart filter',
      value: 1,
      name: 'filter',
    },
    {
      label: 'Show coordinates',
      value: 2,
      name: 'show_coordinates'
    },
    {
      label: 'Split overnight shifts',
      value: 3,
      name: 'split_at_midnight'
    },
  ]
}