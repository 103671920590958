import {useState, useEffect, useCallback, useMemo} from 'react'
import {List, AutoSizer} from 'react-virtualized'
import useStyles from './listUseStyles'

// MUIS
import Stack from '@mui/material/Stack'
import ButtonIcon from '@mui/material/IconButton'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase'

// MUI ICONS
import IconSearch from '@mui/icons-material/Search'
import IconClose from '@mui/icons-material/Close'
import GroupLabel from './GroupLabel'
import ChildLabel from './ChildLabel'


const CustomList = ({groupList, initialObjectList, onItemsSelected}) => {
  const classes = useStyles()

  const [data, setData] = useState([])
  const [mainData, setMainData] = useState([])
  const [totalChild, setTotalChild] = useState(0)
  const [allCheckboxProps, setAllCheckboxProps] = useState({
    isIndeterminate: false,
    isChecked: false,
  })
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    onItemsSelected(mainData.filter(v => v.type !== 'group' && v.isChecked))
  }, [mainData, onItemsSelected])

  useEffect(() => {
    const temp = []
    let childCount = 0
    groupList.forEach(group => {
      const childs = initialObjectList.filter(obj => obj?.group?.id === group?.id)
      if (childs.length < 1) {
        return
      }
      const grpData = {
        ...group,
        groupId: group.id,
        type: 'group',
        childCount: childs.length,
        activeChildCount: childs.length,
        startingIndex: temp.length +1,
        isChecked: false,
        expanded: true,
        isIndeterminate: false,
      }
      temp.push(grpData)
      childs.forEach(child => {
        childCount += 1
        temp.push({
          ...child,
          type: 'child',
          expanded: true,
          isChecked: false,
          isSelectable : true,
        })
      })
    })
    setData(temp)
    setMainData(temp)
    setTotalChild(childCount)
  }, [groupList, initialObjectList])

  const calculateGroupCheckbox = useCallback((copiedData) => {
    const groupIndexes = []
    copiedData.forEach((v, i) => {
      if (v.type === 'group') groupIndexes.push(i)
    })
    groupIndexes.forEach(groupIdx => {
      const selectedChildCount = copiedData.filter(obj => obj?.group?.id === copiedData[groupIdx].id && obj.isChecked).length
      const visibleChildCount = copiedData.filter(obj => obj?.group?.id === copiedData[groupIdx].id && obj.isSelectable).length
      if (selectedChildCount > 0 && selectedChildCount !== copiedData[groupIdx].childCount && visibleChildCount > 0) {
        copiedData[groupIdx] = {
          ...copiedData[groupIdx],
          isIndeterminate: true,
          isChecked: false,
          expanded: true,
          activeChildCount: visibleChildCount,
        }
      }
      if (selectedChildCount === copiedData[groupIdx].childCount) {
        copiedData[groupIdx] = {
          ...copiedData[groupIdx],
          isIndeterminate: false,
          isChecked: true,
          expanded: true,
          activeChildCount: visibleChildCount,
        }
      }
      if (selectedChildCount === 0) {
        copiedData[groupIdx] = {
          ...copiedData[groupIdx],
          isIndeterminate: false,
          isChecked: false,
          expanded: false,
          activeChildCount: visibleChildCount,
        }
      }
      if (visibleChildCount < 1) {
        copiedData[groupIdx] = {
          ...copiedData[groupIdx],
          expanded: false
        }
      } else {
        copiedData[groupIdx] = {
          ...copiedData[groupIdx],
          expanded: true
        }
      }
    })
  }, [])

  const calculateGlobalCheckbox = useCallback((cp) => {
    const totalSelectedChild = cp.filter(obj => obj?.type !== 'group' && obj?.isChecked === true).length
    if (totalChild === totalSelectedChild) {
      setAllCheckboxProps({
        isIndeterminate: false,
        isChecked: true,
      })
    }
    if (totalChild > 0 && totalChild > totalSelectedChild) {
      setAllCheckboxProps({
        isIndeterminate: true,
        isChecked: false,
      })
    }
    if (totalSelectedChild < 1) {
      setAllCheckboxProps({
        isIndeterminate: false,
        isChecked: false,
      })
    }
  }, [totalChild])

  const handleChildCheckboxClick = useCallback((childId) => {
    const cp = [...mainData]
    const childIdx = cp.findIndex(obj => obj?.id=== childId)
    cp[childIdx] = {
      ...cp[childIdx],
      isChecked: !cp[childIdx].isChecked
    }
    calculateGroupCheckbox(cp)
    calculateGlobalCheckbox(cp)
    setMainData(cp)
    setData(cp.filter(obj => {
      if (obj.type === 'group') {return true}
      return obj.expanded === true
    }))
  }, [calculateGlobalCheckbox, calculateGroupCheckbox, mainData])

  const handleGroupCheckboxClick = useCallback((groupId) => {
    const cp = [...mainData]
    const groupIdx = cp.findIndex(obj => obj?.groupId === groupId)
    const groupDetail = cp[groupIdx]
    const shouldChangeCheck = !groupDetail.isIndeterminate && !groupDetail.isChecked
    for (let i=groupDetail.startingIndex; i < groupDetail.startingIndex + groupDetail.childCount; i++) {
      if (searchQuery) {
        if (cp[i].label.toLowerCase().includes(searchQuery)) {
          cp[i] = {
            ...cp[i],
            expanded: true,
            isChecked: shouldChangeCheck
          }
        }
      } else {
        cp[i] = {
          ...cp[i],
          expanded: true,
          isChecked: shouldChangeCheck
        }
      }
    }
    calculateGroupCheckbox(cp)
    calculateGlobalCheckbox(cp)
    setMainData(cp)
    setData(cp.filter(obj => {
      if (obj.type === 'group') {return true}
      return obj.expanded === true
    }))
  }, [calculateGlobalCheckbox, calculateGroupCheckbox, mainData, searchQuery])

  const handleGroupClick = useCallback((groupId) => {
    const copiedData = [...mainData]
    const groupIdx = copiedData.findIndex(obj => obj?.groupId === groupId)
    const groupDetail = copiedData[groupIdx]
    for (let i=groupDetail.startingIndex; i < groupDetail.startingIndex + groupDetail.childCount; i++) {
      if (searchQuery) {
        if (copiedData[i].label.toLowerCase().includes(searchQuery)) {
          copiedData[i] = {
            ...copiedData[i],
            expanded: !copiedData[i].expanded
          }
        }
      } else {
        copiedData[i] = {
          ...copiedData[i],
          expanded: !copiedData[i].expanded
        }
      }
    }
    copiedData[groupIdx] = {
      ...copiedData[groupIdx],
      expanded: !copiedData[groupIdx].expanded
    }
    setMainData(copiedData)
    setData(copiedData.filter(obj => {
      if (obj.type === 'group') {return true}
      return obj.expanded === true
    }))

  }, [mainData, searchQuery])

  const handleChildClick = useCallback((childId) => {
    let copiedData = [...mainData]
    copiedData = copiedData.map(obj => {
      if (obj.type === 'group') {
        return {
          ...obj,
          isChecked: false,
          isIndeterminate: false,
        }
      }
      return {
        ...obj,
        isChecked: false,
      }
    })
    const childIdx = copiedData.findIndex(obj => obj?.id=== childId)
    copiedData[childIdx] = {
      ...copiedData[childIdx],
      isChecked: !copiedData[childIdx].isChecked
    }
    const groupIdx = copiedData.findIndex(obj => obj?.groupId === copiedData[childIdx].group.id)
    const selectedChildCount = copiedData.filter(obj => obj?.group?.id === copiedData[childIdx].group.id && obj.isChecked).length
    if (selectedChildCount > 0 && selectedChildCount !== copiedData[groupIdx].childCount) {
      copiedData[groupIdx] = {
        ...copiedData[groupIdx],
        isIndeterminate: true,
        isChecked: false,
      }
    }
    if (selectedChildCount === copiedData[groupIdx].childCount) {
      copiedData[groupIdx] = {
        ...copiedData[groupIdx],
        isIndeterminate: false,
        isChecked: true,
      }
    }
    if (selectedChildCount === 0) {
      copiedData[groupIdx] = {
        ...copiedData[groupIdx],
        isIndeterminate: false,
        isChecked: false,
      }
    }
    calculateGlobalCheckbox(copiedData)
    setMainData(copiedData)
    setData(copiedData.filter(obj => {
      if (obj.type === 'group') {return true}
      return obj.expanded === true
    }))

  }, [calculateGlobalCheckbox, mainData])

  const handleSelectAll = useCallback(() => {
    let copiedData = []
    let willCheckAll = !allCheckboxProps.isIndeterminate && !allCheckboxProps.isChecked
    copiedData = mainData.map(v => {
      let isChecked = false
      if (searchQuery && v.type !== 'group') {
        isChecked = v.label.toLowerCase().includes(searchQuery) && !v.isChecked
      }
      if (!searchQuery && v.type !== 'group') {
        isChecked = willCheckAll
      }
      return {
        ...v,
        isChecked: isChecked,
        ...(!willCheckAll && v.type === 'group' && {isIndeterminate: false})
      }})
    calculateGroupCheckbox(copiedData)
    setMainData(copiedData)
    setData(copiedData.filter(obj => {
      if (obj.type === 'group') {return true}
      return obj.expanded === true
    }))
    calculateGlobalCheckbox(copiedData)
  }, [allCheckboxProps.isChecked, allCheckboxProps.isIndeterminate, calculateGlobalCheckbox, calculateGroupCheckbox, mainData, searchQuery])

  const doSearch = useCallback((query) => {
    setSearchQuery(query?.toLowerCase() ?? '')
    if (query) {
      const copiedData = [...mainData]
      copiedData.forEach((entry, i) => {
        if (entry.type !== 'group') {
          if (entry.label.toLowerCase().includes(query.toLowerCase())) {
            copiedData[i] = {...copiedData[i], expanded: true, isSelectable: true}
          } else {
            copiedData[i] = {...copiedData[i], expanded: false, isSelectable: false}
          }
        }
      })
      calculateGroupCheckbox(copiedData)
      setMainData(copiedData)
      setData(copiedData.filter(obj => {
        if (obj.type === 'group') {return true}
        return obj.expanded === true
      }))
    } else {
      const copiedData = mainData.map(entry => ({
        ...entry,
        expanded: true,
        ...(entry.type !== 'group' && {isSelectable: true})
      }))
      calculateGroupCheckbox(copiedData)
      setMainData(copiedData)
      setData(copiedData.filter(obj => {
        if (obj.type === 'group') {return true}
        return obj.expanded === true
      }))
    }
  }, [calculateGroupCheckbox, mainData])


  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) {
    const singleData = data[index]
    const type = singleData.type
    if (type === 'group') {
      return <GroupLabel data={singleData} key={key} style={style} classes={classes} handleGroupClick={handleGroupClick} handleGroupCheckboxClick={handleGroupCheckboxClick} />
    }
    return <ChildLabel data={singleData} key={key} style={style} classes={classes} handleChildCheckboxClick={handleChildCheckboxClick} handleChildClick={handleChildClick} />
  }

  return(
    <Box height='100%'>
      <Stack justifyContent='space-between' direction='row' alignItems='center' className={classes.listRoot} id='shann'>
        <Stack direction='row' alignItems='center' >
          <Checkbox className={classes.checkboxMargin} checked={allCheckboxProps.isChecked} indeterminate={allCheckboxProps.isIndeterminate} onClick={(e) => {
            e.stopPropagation()
            handleSelectAll()
          }} />
          <InputBase
            sx={{ ml: 1, flex: 1, marginLeft: '8px', fontSize: '14px' }}
            placeholder='Search'
            inputProps={{ 'aria-label': 'search' }}
            value={searchQuery}
            onChange={e => {
              doSearch(e.target.value ?? '')
            }}
          />
        </Stack>
        <ButtonIcon onClick={() => {
          if (searchQuery) {
            doSearch('')
          }
        }}>
          {searchQuery ? <IconClose /> : <IconSearch />}
        </ButtonIcon>
      </Stack>
      <AutoSizer>
        {({height, width}) => (
          <List
            width={width}
            height={height}
            rowCount={data.length}
            rowHeight={50}
            rowRenderer={rowRenderer}
          />
        )}
      </AutoSizer>
    </Box>
    
  )

}

export default CustomList