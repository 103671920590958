import { useContext } from 'react'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// HOOKS
import useAxiosPrivate from 'hooks/useAxiosPrivate'

//STYLES
import panelGeneratedReportUseStyle from './panelGeneratedReportUseStyle'

//MUI ICON MATERIALS
import Add from '@mui/icons-material/Add'

//MUI MATERIALS
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import { useCallback, useEffect, useState } from 'react'

//SERVICES AND UTILS
import { getGeneratedReportList } from 'services/report'
import { readUserProfileFromLocalStorage } from 'utilities/localStorage'

//TIME LIBRARY
import moment from 'moment'

const PanelGeneratedReport = (props) => {
  const { setIsLoading } = useContext(AllPagesContext)
  
  const { setIsCreatingReport, setPanelObj, onClickReport } = props
  const { hash } = readUserProfileFromLocalStorage()
  const classes = panelGeneratedReportUseStyle()
  const axiosPrivate = useAxiosPrivate()

  const [generatedReports, setGeneratedReports] = useState([])

  const fetchGeneratedReportList = useCallback(async (abortController, isMounted) => {
    setIsLoading(true)

    const result = await getGeneratedReportList(abortController.signal, {
      hash
    }, axiosPrivate)
    if(isMounted) {
      setGeneratedReports(result.data.list)
    }

    setIsLoading(false)
  }, [hash, axiosPrivate])

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    fetchGeneratedReportList(abortController, isMounted)

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [fetchGeneratedReportList])


  return (
    <Stack className={`${classes.panelContainer} zoom`}>
      <Stack className={classes.headerStyle}>
        <Typography className={classes.titleHeaderStyle}>Generated Reports</Typography>
      </Stack>
      <List disablePadding>
        <ListItemButton className={classes.primaryButtonStyle} onClick={() => {
          setIsCreatingReport(curr => !curr)
          // setPanelObj(prev => !prev)
        }
        }>
          <Add className={classes.textBlue}/>
          <Typography className={classes.textBlue}>Create Report</Typography>
        </ListItemButton>
      </List>
      <div style={{overflow: 'auto', height: '100%'}}>
        {generatedReports.map((report, index) => (
          <ListItemButton
            key={index} className={classes.itemStyle} onClick={() => {
              onClickReport(generatedReports[index])}}>
            <ListItemText className={classes.itemTitleStyle} primary={report.title}/>
            <ListItemText className={classes.itemDescStyle} primary={`Generated: ${moment(report.created, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY [at] hh:mm A')}`}/>
          </ListItemButton>
        ))}
      </div>
    </Stack>
  )
}
export default PanelGeneratedReport