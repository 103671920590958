import makeStyles from '@mui/styles/makeStyles'

const useStyle = makeStyles((theme) => ({
  backgroundStyle:{
    backgroundRepeat: 'no-repeat',
    flex: 1,
    objectFit: 'cover',
    height: '100%',
    maxWidth: '100%',
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    padding: '32px 60px',
    flexBasis: 380,
    minWidth: 380,
  }
}))

export default useStyle