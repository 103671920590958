import { types } from 'constants/geofence'
import { getZoneList, getZonePointList } from 'services/zone'


export const getProcessedZoneList = async (
  inputSignal, 
  inputBodyParams,
) => {
  try {
    const { list: zones } = (await getZoneList(inputSignal, inputBodyParams))?.data
    const polygons = []
    const circles = []

    await Promise.all(zones.map(async (zone) => {
      const { type } = zone
      if(type === types.CIRCLE) {
        circles.push(zone)
      }
      else {
        const { list: detail } = (await getZonePointList(inputSignal, {
          ...inputBodyParams,
          zone_id: zone.id
        }))?.data

        polygons.push(detail)
      }
    }))

    return [...polygons, ...circles]
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}