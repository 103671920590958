import { useEffect, useRef, useCallback } from 'react'

// LEAFLET
import L from 'leaflet'
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'

const GeofenceList = (props) => {
  const { map, hasGeofenceListFinishedLoading, selectedGeofenceList } = props

  const geoJsonGeofenceListRef = useRef()

  const drawGeofenceListToMap = useCallback(() => {
    if (geoJsonGeofenceListRef.current)
      geoJsonGeofenceListRef.current.clearLayers()

    // ADD GEOFENCE LIST (GEO JSON DATA) FROM THE SELECTED GEOFENCE LIST
    const featuresList = selectedGeofenceList.map((item) => {
      if (item.radius) {
        const { bounds, center, ...properties } = item

        return {
          type: 'Feature',
          properties: properties,
          geometry: {
            type: 'Point',
            coordinates: [center.lng, center.lat, 0],
          },
          bounds,
        }
      }
      else if (item.length !== 0) {
        return {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            coordinates: [
              item.map((point) => [point.lng, point.lat, 0]),
            ],
          },
        }
      } 
      else {
        return null
      };
    })

    const geoJsonData = {
      type: 'FeatureCollection',
      features: featuresList,
    }

    // SHOW GEOFENCES TO THE MAP
    geoJsonGeofenceListRef.current = L.geoJson(geoJsonData, {
      pointToLayer: (feature, latlng) => {
        if (feature.properties.radius)
          return new L.Circle(latlng, feature.properties.radius)
        else return new L.Marker(latlng)
      },
      style: (feature) => {
        return {
          fillColor: feature.properties?.color ? `#${feature.properties?.color}` : '#F44336',
          color: feature.properties?.color ? `#${feature.properties?.color}` : '#F44336',
          fillOpacity: 0.08,
          opacity: 0,
        }
      },
    })

    geoJsonGeofenceListRef.current.addTo(map)

    const newMapBoundList = geoJsonGeofenceListRef.current.getBounds()
    map.fitBounds(newMapBoundList)
  }, [map, selectedGeofenceList])

  useEffect(() => {
    if (
      map &&
      selectedGeofenceList.length > 0 &&
      hasGeofenceListFinishedLoading
    ) {
      drawGeofenceListToMap()
    }
  }, [
    map,
    selectedGeofenceList,
    hasGeofenceListFinishedLoading,
    drawGeofenceListToMap,
  ])

  return null
}

export default GeofenceList
