import {useCallback, useEffect, useState} from 'react'

//MUI
import { 
  Collapse, 
  Divider, 
  Stack, 
  Tooltip,
  Checkbox,
  ListItemText,
  ListItemIcon,
  ListItem,
  List
} from '@mui/material'
import { styled } from '@mui/material/styles'

import useSettingsChecklistStyles from './useSettingsChecklistStyles'

//ICONS
import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'
import IconHelpOutline from '@mui/icons-material/HelpOutline'

import MultiShift from '../MultiShift'
import { useReportPageContext } from 'contexts/ReportPageContext'
import {checkListItems, advancedChecklistItems} from './constants'

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) ~ :not(style)': {
    marginTop: theme.spacing(2),
  },
}))

const SettingsChecklist = () => {
  const classes = useSettingsChecklistStyles()
  const [isCollapseOpen, setIsCollapseOpen] = useState(false)
  const { reportType, setChecklist, checklist } = useReportPageContext()

  const handleSettingsClick = useCallback((value) => {
    const newSelected = {...checklist}
    newSelected[value] = !newSelected[value]
    setChecklist(newSelected)
  }, [checklist, setChecklist])

  useEffect(() => {
    const check = {}
    checkListItems[reportType].forEach(v => {
      check[v.name] = false
    })
    advancedChecklistItems[reportType]?.forEach(v => {
      check[v.name] = false
    })
    if (reportType === 'trips') {
      check.plugin_id = 4
    }
    if (reportType === 'stops') {
      check.plugin_id = 6
    }
    if (reportType === 'tripsAndStops') {
      check.plugin_id = 77
    }
    setChecklist(check)
  }, [reportType, setChecklist])

  return(
    <Stack>
      <List sx={{ width: '100%'}}>
        {checkListItems[reportType].map((value) => {
          const labelId = `checkbox-list-label-${value.name}`

          return (
            <ListItem
              key={value.name}
              disablePadding
              className={classes.listItem}
              onClick={() => {handleSettingsClick(value.name)}}
            >
              <ListItemIcon className={classes.listIcon}>
                <Checkbox
                  edge='start'
                  checked={checklist?.[value.name] ?? false}
                  tabIndex={-1}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <Stack direction='row' alignItems='center'>
                <ListItemText className={classes.listItemText} id={labelId} primary={value.label} />
                {value?.tooltipText && 
                  <Tooltip title={value.tooltipText}>
                    <IconHelpOutline className={classes.tooltip} />
                  </Tooltip>
                }
              </Stack>
            </ListItem>
          )
        })}
      </List>
      {reportType === 'tripsAndStops' && <MultiShift />}
      {reportType !== 'stops' && 
        <Root>
          <Divider className={classes.divider} onClick={() => setIsCollapseOpen(prev => !prev)}>
            <Stack direction='row' alignItems='center'>
              Advanced Settings
              {isCollapseOpen ? <IconExpandLess /> : <IconExpandMore />}
            </Stack>
          </Divider>
        </Root>
      }
      {(reportType === 'trips' || reportType === 'tripsAndStops') && <Collapse in={isCollapseOpen} timeout='auto' unmountOnExit>
        <List sx={{ width: '100%'}}>
          {advancedChecklistItems[reportType].map((value) => {
            const labelId = `checkbox-list-label-${value.name}`

            return (
              <ListItem
                key={value.name}
                disablePadding
                className={classes.listItem}
                onClick={() => {handleSettingsClick(value.name)}}
              >
                <ListItemIcon className={classes.listIcon}>
                  <Checkbox
                    edge='start'
                    checked={checklist?.[value.name] ?? false}
                    tabIndex={-1}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.label} />
              </ListItem>
            )
          })}
        </List>
      </Collapse>}
    </Stack>
    
  )
}

export default SettingsChecklist