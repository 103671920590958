//STYLES
import usePanelDetailReport from './usePanelDetailReport'

//MUI ICON MATERIALS
import IconDownload from '@mui/icons-material/Download'
import IconClose from '@mui/icons-material/Close'

//MUI MATERIALS
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Chip from '@mui/material/Chip'

import DataGridTable from 'components/Customs/CustomDataGrid'

import { mainData } from './dummy'

const PanelDetailReports = (props) => {
  const { setIsCreatingReport, setPanelObj, selectedReport, setReport } = props
  const classes = usePanelDetailReport()
  const currSelectedReport = { event: 'a', createdDate: 'a', selectedObj: [{label: 'Shan'}, {label: 'Taylor'}] }

  const columns = [
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      minWidth: 150,
      hide: false,
      headerClassName: classes.tableHeader,
      isFilterShown: false,
      isSortShown: false,
    },
    {
      field: 'latitude',
      headerName: 'Position Latitude',
      minWidth: 150,
      hide: false,
      isFilterShown: false,
      isSortShown: false,
      headerClassName: classes.tableHeader,
    },
    {
      field: 'longitude',
      headerName: 'Position Longitude',
      minWidth: 150,
      hide: false,
      isFilterShown: false,
      isSortShown: false,
      headerClassName: classes.tableHeader,
    },
    {
      field: 'speed',
      headerName: 'Position Speed',
      minWidth: 150,
      hide: false,
      isFilterShown: false,
      isSortShown: false,
      headerClassName: classes.tableHeader,
    },
    {
      field: 'address',
      headerName: 'Address',
      minWidth: 250,
      hide: false,
      isFilterShown: false,
      isSortShown: false,
      headerClassName: classes.tableHeader,
    },
  ]

  return (
    <Stack className={`${classes.panelContainer} zoom ${classes.absolute}`}>
      <Stack className={classes.headerStyle} direction='row'>
        <IconButton size='small' onClick={() => {
          setIsCreatingReport(curr => !curr)
          setPanelObj(false)
        }
        }>
          <IconDownload className={classes.textWhite} fontSize='small' />
        </IconButton>
        <Stack justifyContent='center' alignItems='center' width='100%' >
          <Typography className={classes.titleHeaderStyle}>{currSelectedReport.selectedObj[0].label}</Typography>
        </Stack>
        <IconButton size='small' onClick={() => setReport(null)}>
          <IconClose className={classes.textWhite} fontSize='small' />
        </IconButton>
      </Stack>

      <Stack direction='row' className={classes.chipWrapper} gap='4px'>
        {currSelectedReport.selectedObj.map((v,i) => <Chip key={i} size='small' deleteIcon={<IconClose className={classes.chipIconStyle} />} className={classes.chipStyle} onDelete={() => {}} label={v.label} />)}
      </Stack>


      <DataGridTable
        // BASE
        columns={columns}
        rows={mainData}
        pagination={false}
        isFilterOn={false}
        checkboxSelection={false}
        className={classes.tableClass}
      />
    </Stack>
  )
}

export default PanelDetailReports