import { createContext, useContext, useState, useMemo } from 'react'

const ReportPageContext = createContext()

const ReportPageContextProvider = ({children}) => {
  const [reportType, setReportType] = useState('')
  const [checklist, setChecklist] = useState(null)
  const [timeFilter, setTimeFilter] = useState(null)
  const [selectedDay, setSelectedDay] = useState([])
  const [shifts, setShifts] = useState([
    {
      name: '',
      start_time: '07:00:00',
      end_time: '09:00:00'
    }
  ])

  const panelTitle = useMemo(() => {
    if (reportType === 'trips') {
      return 'Trips'
    }
    if (reportType === 'stops') {
      return 'Stops'
    }
    if (reportType === 'tripsAndStops') {
      return 'Trips and stops by shifts'
    }
  }, [reportType])

  return (
    <ReportPageContext.Provider
      value={{
        reportType, setReportType,
        checklist, setChecklist,
        timeFilter, setTimeFilter,
        shifts, setShifts,
        selectedDay, setSelectedDay,
        panelTitle
      }}
    >
      {children}
    </ReportPageContext.Provider>
  )
}

const useReportPageContext = () => useContext(ReportPageContext)

export { ReportPageContextProvider, ReportPageContext, useReportPageContext }