//APIS
import axios from 'apis/axios'

export const getZoneList = async (
  inputSignal, 
  inputBodyParams, 
  inputAxios,
) => {
  try {
    const ax = inputAxios ?? axios
    const response = await ax.post(
      'zone/list',
      {
        ...inputBodyParams,
      }, 
      { 
        signal: inputSignal,
      },
    )
    
    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const getZonePointList = async (
  inputSignal, 
  inputBodyParams, 
  inputAxios,
) => {
  try {
    const ax = inputAxios ?? axios
    const response = await ax.post(
      'zone/point/list',
      {
        ...inputBodyParams,
      }, 
      { 
        signal: inputSignal,
      },
    )
      
    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}