import { 
  Stack, 
  Typography, 
  TextField,
  IconButton,
  FormControl, 
  Select, 
  MenuItem,
  Box, 
  InputLabel,
  Button } from '@mui/material'

import IconDelete from '@mui/icons-material/Delete'

import { timeOptionList } from '../ModalPicker/customPickerData'
import { useReportPageContext } from 'contexts/ReportPageContext'

import useMultiShiftStyles from './useMultiShiftStyles'


const MultiShift = () => {
  const classes = useMultiShiftStyles()
  const {shifts, setShifts} = useReportPageContext()

  const handleStartTimeChange = (value, i) => {
    setShifts(prevState => {
      const temp = [...prevState]
      temp[i] = {
        ...temp[i],
        start_time: value
      }
      return temp
    })
  }

  const handleEndTimeChange = (value, i) => {
    setShifts(prevState => {
      const temp = [...prevState]
      temp[i] = {
        ...temp[i],
        end_time: value
      }
      return temp
    })
  }

  const handleNameChange = (value, i) => {
    setShifts(prevState => {
      const temp = [...prevState]
      temp[i] = {
        ...temp[i],
        name: value
      }
      return temp
    })
  }

  return(
    <Stack>
      <Typography variant='body2' className={classes.textColor}>
        Group by shifts
      </Typography>
      {shifts?.map((v, i) => (<Stack className={classes.shiftRoot} key={`shift${i}`}> 
        <Stack direction='row' alignItems='center' justifyContent='center'>
          <TextField
            className={classes.textField}
            label='Name of shift' 
            variant='standard' 
            onChange={(e) => handleNameChange(e.target.value, i)}
            value={shifts[i].name} />
          <IconButton onClick={() => {
            setShifts(prevState => {
              const temp = [...prevState]
              temp.splice(i, 1)
              return temp
            })
          }}>
            <IconDelete />
          </IconButton>
        </Stack>
        <Stack direction='row' alignItems='center' className={classes.timeWrapper}>
          <FormControl
            variant='standard'
            className={classes.dateAndTimeSelect}
            id='sh234'
          >
            <InputLabel className={classes.inputLabel}>Start</InputLabel>
            <Select
              value={shifts[i].start_time}
              onChange={(event) => {
                handleStartTimeChange(event.target.value, i)
              }}
              MenuProps={{ PaperProps: { sx: { maxHeight: '400px' } } }}
            >
              {timeOptionList.map((item, index) => (
                <MenuItem key={index} value={item.time}>
                  {item.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box className={classes.startAndEndDivider} />
          <FormControl
            variant='standard'
            className={classes.dateAndTimeSelect}
          >
            <InputLabel className={classes.inputLabel}>End</InputLabel>
            <Select
              value={shifts[i].end_time}
              onChange={(event) => {
                handleEndTimeChange(event.target.value, i)
              }}
              MenuProps={{ PaperProps: { sx: { maxHeight: '400px' } } }}
            >
              {timeOptionList.map((item, index) => (
                <MenuItem key={index} value={item.time}>
                  {item.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{width: '28px'}}></div>
        </Stack>
      </Stack>))}
      <Button variant='text' className={classes.overrideTextButton} onClick={() => {
        setShifts(prev => [...prev,
          {
            name: '',
            start_time: '07:00:00',
            end_time: '09:00:00'
          }
        ])
      }}>Add Shift</Button>

    </Stack>

  )
}

export default MultiShift