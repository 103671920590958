// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { grey } from '@mui/material/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  markerContainer: {
    position: 'relative',
  },
  markerParking: {
    border: '1px solid white',
    borderRadius: '50%',
    textAlign: 'center',
    color: 'white',
    '&.MuiAvatar-root': {
      width: 24,
      height: 24,
      fontSize: 14,
    },
  },
  markerLabel: {
    position: 'absolute',
    display: 'block',
    left: 24,
    top: 24,
    color: 'white',
    padding: '2px 4px',
    border: `1px solid ${grey[100]}`,
    minWidth: 'fit-content',
  },
}))

export default useStyles