// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  panelContainer: {
    boxShadow: theme.shadows,
    border: '0.5px solid lightgrey',
    height: '100%',
    minWidth: '320px',
    width: '320px',
    overflow: 'hidden',
    backgroundColor: 'white'
  },
  headerStyle: {
    backgroundColor: 'black',
    padding: '15px',
    borderBottom: '0.5px solid lightgrey',
  },
  titleHeaderStyle: {
    margin: 0,
    fontSize: '20px',
    fontWeight: 600,
    color: 'white',
    display: 'flex',
    justifyContent: 'start'
  },
  primaryButtonStyle:{
    display: 'flex',
    cursor: 'pointer',
    padding: '15px',
    borderBottom: '0.5px solid lightgrey',
  },
  itemStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    cursor: 'pointer',
    padding: '15px',
    borderBottom: '0.5px solid lightgrey',
  },
  itemTitleStyle:{
    margin: 0,
    justifyContent: 'center',
    fontSize: '14px'
  },
  itemDescStyle:{
    fontSize: '12px !important',
    opacity: '50%',
    margin: 0,
    justifyContent: 'center'
  },
  textBlue: {
    color: '#179FD8',
    fontSize: '14px'
  },
  absolute: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  }
}))

export default useStyles