//REACT
import { useCallback, useState, useContext } from 'react'
import { AllPagesContext } from 'contexts/AllPagesContext'

// HOOKS
import useAxiosPrivate from 'hooks/useAxiosPrivate'

//IMAGES
import frame from 'assets/images/frame.svg'

//MUI MATERIAL
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

//STYLES
import reportUseStyle from './reportUseStyle'

//COMPONENT
import PanelCreateReports from './PanelCreateReports/PanelCreateReports'
import PanelGeneratedReport from './PanelGeneratedReports/PanelGeneratedReports'
import PanelObject from './PanelObject/PanelObject'
import PanelTrips from './PanelTrips/PanelTrips'
import PanelDetailReport from './PanelDetailReport'

//SERVICES AND UTILS
import { generateReport } from 'services/report'
import { readUserProfileFromLocalStorage } from 'utilities/localStorage'
import { useReportPageContext } from 'contexts/ReportPageContext'

const absolute = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
}

const Reports = () => {
  const { hash } = readUserProfileFromLocalStorage()
  const { 
    showSnackbar, 
    setIsLoading,
  } = useContext(AllPagesContext)
  const classes = reportUseStyle()
  const axiosPrivate = useAxiosPrivate()
  const [isCreatingReport, setIsCreatingReport] = useState(false)
  const [isShowObjPanel, setShowObjPanel] = useState(false)
  const [selectedReport, setSelectedReport] = useState(null)
  const [selectedItems, setSelectedItems] = useState([])
  const [tripMetadata, setTripMetada] = useState({
    title: 'Trip',
    from: '',
    to: '',
  })
  const {timeFilter, selectedDay, checklist, shifts, reportType} = useReportPageContext()

  const buildReport = useCallback(async () => {
    setIsLoading(true)
    
    const abortController = new AbortController()
    const payload = {
      hash,
      title: tripMetadata.title,
      trackers: selectedItems.map(v => v.id),
      from: tripMetadata.from,
      to: tripMetadata.to,
      time_filter: {
        ...timeFilter,
        weekdays: selectedDay
      },
      plugin: {
        ...checklist,
        ...(reportType === 'tripsAndStops' && {shifts: shifts})
      }
    }

    const result = await generateReport(abortController.signal, payload, axiosPrivate)

    if (result.status === 'Canceled' || result.status === 'No Server Response' || result.status !== 200) {
      showSnackbar('error', result.statusText || result.status)
      return
    }

    setIsLoading(false)
    showSnackbar('success', 'Report Created')
    setIsCreatingReport(false)
    setShowObjPanel(false)
  }, [setIsLoading, hash, tripMetadata.title, tripMetadata.from, tripMetadata.to, selectedItems, timeFilter, selectedDay, checklist, reportType, shifts, axiosPrivate, showSnackbar])

  return (
    <Box sx={{
      position: 'relative',
      width: '100%',
      height: '100%'
    }}>
      <Stack className={`${classes.reportSection} zoom`} sx={{
        ...absolute,
        width: '100%',
        paddingLeft: '344px'
      }}>
        <Stack
          component='img'
          alt='Lacak io.'
          sx={{
            width: '75%',
            maxWidth: '600px',
            objectFit: 'contain'
          }}
          src={frame}
        />
      </Stack>
      <Stack direction='row' className={`${classes.reportContainer} no-zoom`}>
        { isCreatingReport === true ? 
          <PanelCreateReports setPanelObj={(val) => setShowObjPanel(val)} setIsCreatingReport={setIsCreatingReport} /> 
          : 
          <PanelGeneratedReport setIsCreatingReport={setIsCreatingReport} onClickReport={(rep) => setSelectedReport(rep)}/> }
        {isShowObjPanel && <PanelObject setSelectedItems={setSelectedItems} />}
        {isShowObjPanel && <PanelTrips setTripMetada={setTripMetada} onBuildReport={buildReport} />}
        {selectedReport && !isShowObjPanel && <PanelDetailReport selectedReport={selectedReport} setReport={setSelectedReport} />}
      </Stack>
    </Box>
    
  )
}

export default Reports