//APIS
import axios from 'apis/axios'

export const getGeneratedReportList = async (
  inputSignal, 
  inputBodyParams, 
  inputAxios,
) => {
  try {
    const ax = inputAxios ?? axios
    const response = await ax.post(
      'report/tracker/list', 
      {
        ...inputBodyParams,
      }, 
      { 
        signal: inputSignal,
      },
    )
    
    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const generateReport = async (
  inputSignal, 
  inputBodyParams, 
  inputAxios,
) => {
  try {
    const ax = inputAxios ?? axios
    const response = await ax.post(
      'report/tracker/generate', 
      {
        ...inputBodyParams,
      }, 
      { 
        signal: inputSignal,
      },
    )
    
    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}