import { useMemo, useState, useContext, useEffect } from 'react'
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

import usePanelStyles from './usePanelStyles'

//MUI ICON MATERIALS
import IconNearMe from '@mui/icons-material/NearMe'
import IconFormatColorText from '@mui/icons-material/FormatColorText'
import IconInsertInvitation from '@mui/icons-material/InsertInvitation'
import ModalPicker from './ModalPicker'

//TIME LIBRARY
import { format } from 'date-fns'
import moment from 'moment'
import PluginFilter from './PluginFilter'
import { useReportPageContext } from 'contexts/ReportPageContext'

const PanelTrips = ({setTripMetada, onBuildReport}) => {
  const { setIsLoading } = useContext(AllPagesContext)
  const { panelTitle } = useReportPageContext()
  const classes = usePanelStyles()
  const [isPickerOpen, setIsPickerOpen] = useState(false)
  const [reportTitle, setReportTitle] = useState(panelTitle)
  const [selectedDates, setSelectedDates] = useState([moment().set({
    hour: 7,
    minute: 0,
    second: 0
  }).toDate(), moment().set({
    hour: 7,
    minute: 0,
    second: 0
  }).add(2, 'day').toDate()])

  const formattedString = useMemo(() => {
    if (selectedDates?.length < 1 || !selectedDates) return ''
    const formatedDateRange = `${format(
      new Date(selectedDates[0]),
      'MM/dd/yyyy HH:mm aa'
    )} - ${format(new Date(selectedDates[1]), 'MM/dd/yyyy HH:mm aa')}`
    return formatedDateRange
  }, [selectedDates])

  useEffect(() => {
    setTripMetada({
      title: reportTitle,
      from: moment(selectedDates[0]).format('YYYY-MM-DD HH:mm:ss'),
      to: moment(selectedDates[1]).format('YYYY-MM-DD HH:mm:ss'),
    })
  }, [reportTitle, selectedDates, setTripMetada])

  useEffect(() => {
    setReportTitle(panelTitle)
  }, [panelTitle])

  return (
    <Stack className={`${classes.panelContainer} ${classes.absolute}`}>
      <Stack className={`${classes.headerStyle} zoom`} direction='row'>
        <Stack alignItems='center' justifyContent='center'>
          <IconNearMe className={classes.textWhite} fontSize='small' />
        </Stack>
        <Typography className={classes.titleHeaderStyle}>{panelTitle}</Typography>
      </Stack>
      <Stack className={`${classes.root} zoom`}>
        <Stack direction='row' alignItems='center' className={classes.inputWrapper}>
          <IconFormatColorText className={classes.iconStyle} />
          <TextField
            className={classes.textField}
            label='Report Title' 
            variant='standard' 
            onChange={(e) => setReportTitle(e.target.value)}
            value={reportTitle} />
        </Stack>
        <Stack direction='row' alignItems='center' className={classes.inputWrapper}>
          <IconInsertInvitation className={classes.iconStyle} />
          <TextField
            onClick={() => {
              setIsPickerOpen(true)
            }}
            className={classes.textField}
            label='Date Range' 
            variant='standard' 
            value={formattedString} />
        </Stack>
        <PluginFilter />
        <Stack height='100%' />
        <Stack
          justifyContent='end'
          direction='row'
          alignItems='center'
          className={classes.bottomButtonWrapper}>
          <Button variant='text' className={classes.buttonCancelText}>Cancel</Button>
          <Button onClick={async () => { 
            setIsLoading(true)
            await onBuildReport()
            setIsLoading(false)
          }} variant='text' className={classes.buttonOkText}>Build Report</Button>
        </Stack>
        <ModalPicker handleClose={(dateValue) => {
          setIsPickerOpen(false)
          if (dateValue) {
            setSelectedDates(dateValue)
          }
        }} open={isPickerOpen} />
      </Stack>
    </Stack>
      
  )
}

export default PanelTrips