import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
//MUI
import { 
  Box, 
  Modal, 
  Typography, 
  Stack, 
  Divider, 
  List, 
  ListItemButton, 
  ListItemText, 
  Input, 
  FormControl, 
  Select, 
  MenuItem, 
  TextField, 
  Button, 
  IconButton } from '@mui/material'
import { AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker'

//MUI ICON
import IconClose from '@mui/icons-material/Close'

//TIME LIBRARY
import { format } from 'date-fns'
import moment from 'moment'

//CUSTOM STYLE
import useModalStyles from './useModalStyles'

//CONST
import { dateRangeList, timeOptionList } from './customPickerData'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '600px',
  maxHeight: '600px',
  bgcolor: 'white',
  borderRadius: '6px',
  boxShadow: 24,
  pt: 0,
}

const dateFormat = 'DD/MM/yyyy'

const ModalPicker = ({open, handleClose}) => {
  const classes = useModalStyles()
  const [dateValue, setDateValue] = useState([moment().set({
    hour: 7,
    minute: 0,
    second: 0
  }).toDate(), moment().set({
    hour: 7,
    minute: 0,
    second: 0
  }).add(2, 'day').toDate()])
  const [startTime, setStartTime] = useState('07:00:00')
  const [endTime, setEndTime] = useState('07:00:00')
  const [startDateText, setStartDateText] = useState(format(moment().toDate(), 'dd/MM/yyyy'))
  const [endDateText, setEndDateText] = useState(format(moment().add(2, 'day').toDate(), 'dd/MM/yyyy'))
  const [selectedDateRange, setSelectedDateRange] = useState()

  const countDays = useMemo(() => moment(dateValue[1]).diff(dateValue[0], 'days') + 1, [dateValue])
  
  useEffect(() => {
    if (dateValue) {
      setStartDateText(format( dateValue?.[0], 'dd/MM/yyyy'))
      if (dateValue?.[1]){
        setEndDateText(format( dateValue?.[1], 'dd/MM/yyyy'))
      }
    }
  }, [dateValue])

  const handleStartDateTextChange = useCallback((inputValue) => {
    if (moment(inputValue, dateFormat, true).isValid()) {
      setDateValue(prev => [moment(inputValue, dateFormat, true).toDate(), prev[1]])
    }
  }, [])

  const handleEndDateTextChange = useCallback((inputValue) => {
    if (moment(inputValue, dateFormat, true).isValid()) {
      setDateValue(prev => [prev[0], moment(inputValue, dateFormat, true).toDate()])
    }
  }, [])

  const handleDateRangeItemClick = (inputItem) => {
    let startDate
    let endDate

    if (inputItem === 'Yesterday') {
      startDate = moment().subtract(1, 'days').startOf('days').toDate()
      endDate = moment().subtract(1, 'days').endOf('days').toDate()
    } else if (inputItem === 'Last week') {
      startDate = moment().subtract(1, 'weeks').startOf('week').toDate()
      endDate = moment().subtract(1, 'weeks').endOf('week').toDate()
    } else if (inputItem === 'Last month') {
      startDate = moment().subtract(1, 'months').startOf('months').toDate()
      endDate = moment().subtract(1, 'months').endOf('months').toDate()
    } else if (inputItem === 'Today') {
      startDate = moment().startOf('day').toDate()
      endDate = moment().endOf('day').toDate()
    } else if (inputItem === 'This week') {
      startDate = moment().startOf('week').toDate()
      endDate = moment().endOf('week').toDate()
    } else if (inputItem === 'This month') {
      startDate = moment().startOf('month').toDate()
      endDate = moment().endOf('month').toDate()
    } else if (inputItem === 'Tomorrow') {
      startDate = moment().add(1, 'days').startOf('days').toDate()
      endDate = moment().add(1, 'days').endOf('days').toDate()
    } else if (inputItem === 'Next week') {
      startDate = moment().add(1, 'weeks').startOf('weeks').toDate()
      endDate = moment().add(1, 'weeks').endOf('weeks').toDate()
    } else if (inputItem === 'Next month') {
      startDate = moment().add(1, 'months').startOf('months').toDate()
      endDate = moment().add(1, 'months').endOf('months').toDate()
    }
    setSelectedDateRange(inputItem)
    setDateValue([startDate, endDate])
    setStartTime(moment(startDate).format('HH:mm:ss'))
    setEndTime(moment(startDate).format('HH:mm:ss'))
  }

  const handleStartTimeChange = useCallback((input) => {
    setStartTime(input)
    const inputValueInMoment = moment(input, 'HH:mm:ss')
    if (moment(input, 'HH:mm:ss', true).isValid()) {
      setDateValue((current) => [
        moment(current[0])
          .set({
            hour: inputValueInMoment.get('hour'),
            minute: inputValueInMoment.get('minute'),
            second: inputValueInMoment.get('second')
          })
          .toDate(),
        current[1]
      ])
    }
  }, [])

  const handleEndTimeChange = useCallback((input) => {
    setEndTime(input)
    if (!dateValue[1]) return
    const inputValueInMoment = moment(input, 'HH:mm:ss')
    if (moment(input, 'HH:mm:ss', true).isValid()) {
      setDateValue((current) => [
        current[0],
        moment(current[1])
          .set({
            hour: inputValueInMoment.get('hour'),
            minute: inputValueInMoment.get('minute'),
            second: inputValueInMoment.get('second')
          })
          .toDate(),
      ])
    }
  }, [dateValue])

  return(
    <Modal
      open={open}
      onClose={() => handleClose(null)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style} id='shannon'>
        <Stack direction='row' className={classes.modalHeader} justifyContent='space-between' alignItems='center' sx={{}}>
          <Typography variant='h6'>
            Select The Period
          </Typography>
          <IconButton onClick={() => handleClose()}>
            <IconClose />
          </IconButton>
        </Stack>
        <Stack direction='row'>
          <Stack direction='column' maxWidth='194px' borderRight='solid' borderColor='#EFEFEF'>
            {dateRangeList.map((dateRangeType, dateRangeTypeIndex) => (
              <Fragment key={dateRangeTypeIndex}>
                <List>
                  {dateRangeType.map((item, index) => (
                    <ListItemButton
                      key={index}
                      onClick={() => handleDateRangeItemClick(item, index)}
                      className={selectedDateRange === item ? `${classes.leftPanelItemText} ${classes.leftPanelItemButtonActive}`: classes.leftPanelItemText}
                    >
                      <ListItemText
                        primary={item}
                      />
                    </ListItemButton>
                  ))}
                </List>

                {dateRangeTypeIndex !== dateRangeList.length - 1 && (
                  <Divider className={classes.leftPanelItemDivider} />
                )}
              </Fragment>
            ))}
          </Stack>
          <Stack marginLeft='22px' marginTop='22px' marginRight='33px'>
            <Typography variant='subtitle2' className={classes.title}>
              Date range:
            </Typography>
            <Stack direction='row' alignItems='center'>
              <Input
                className={classes.dateAndTimeInput}
                value={startDateText}
                onChange={(event) => {
                  setStartDateText(event.target.value)
                  handleStartDateTextChange(event.target.value)
                }}
              />
              <FormControl
                variant='standard'
                className={classes.dateAndTimeSelect}
                id='sh234'
              >
                <Select
                  value={startTime}
                  onChange={(event) => {
                    handleStartTimeChange(event.target.value)
                  }}
                  MenuProps={{ PaperProps: { sx: { maxHeight: '400px' } } }}
                >
                  {timeOptionList.map((item, index) => (
                    <MenuItem key={index} value={item.time}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box className={classes.startAndEndDivider} />
              <Input
                className={classes.dateAndTimeInput}
                value={endDateText}
                onChange={(event) => {
                  setEndDateText(event.target.value)
                  handleEndDateTextChange(event.target.value)
                }}
              />
              <FormControl
                variant='standard'
                className={classes.dateAndTimeSelect}
              >
                <Select
                  sx={{maxHeight: '500px'}}
                  value={endTime}
                  onChange={(event) => {
                    handleEndTimeChange(event.target.value)
                  }}
                  MenuProps={{ PaperProps: { sx: { maxHeight: '400px' } } }}
                >
                  {timeOptionList.map((item, index) => (
                    <MenuItem key={index} value={item.time}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Box sx={{marginTop: '12px'}} id='time'>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDateRangePicker
                  displayStaticWrapperAs='desktop'
                  className={classes.datePicker}
                  value={dateValue}
                  onChange={(newValue) => setDateValue(newValue)}
                  renderInput={(startProps, endProps) => (
                    <>
                      <TextField {...startProps} />
                      <Box sx={{ mx: 2 }}> to </Box>
                      <TextField {...endProps} />
                    </>
                  )}
                />
              </LocalizationProvider>
            </Box>

            <Stack direction='row' className={classes.modalFooter} alignItems='center'>
              <Typography variant='subtitle2' className={classes.countDays}>
                Selected {countDays} days
              </Typography>
              <Stack direction ='row'>
                <Button variant='text' className={classes.buttonCancelText} onClick={() => handleClose()}>Batal</Button>
                <Button variant='text' className={classes.buttonText} onClick={() => {
                  const startTimeMoment = moment(startTime, 'HH:mm:ss')
                  const endTimeMoment = moment(endTime, 'HH:mm:ss')
                 
                  handleClose([moment(dateValue[0]).set({
                    hour: startTimeMoment.get('hour'),
                    minute: startTimeMoment.get('minute'),
                    second: startTimeMoment.get('second')
                  })
                    .toDate(), moment(dateValue[1]).set({
                    hour: endTimeMoment.get('hour'),
                    minute: endTimeMoment.get('minute'),
                    second: endTimeMoment.get('second')
                  })
                    .toDate()])
                }}>Pilih</Button>
              </Stack>
            </Stack>

          </Stack>
        </Stack>
      </Box>
    </Modal>
  )
}

export default ModalPicker