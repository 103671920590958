import React, { useCallback, useEffect, useState } from 'react'
import ReactDOMServer from 'react-dom/server'

// COMPONENTS
import MapChildrenZoom from 'components/Map/PanelZoom/MapChildrenZoom'
import PanelChangeMap from 'components/Map/PanelChangeMap/PanelChangeMap'
import PanelZoom from 'components/Map/PanelZoom/PanelZoom'

// CONSTANTS
import mapOptionList from 'constants/mapOptionList'
import { colors } from 'constants/colors'

// LEAFLET
import {
  MapContainer, 
  TileLayer,
} from 'react-leaflet'
import L from 'leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'

// MUIS
import Avatar from '@mui/material/Avatar'

// STYLES
import useStyles from './mapUseStyles'
import ButtonFullscreen from './ButtonFullscreen/ButtonFullscreen'
import CustomMarker from './CustomMarker/CustomMarker'
import GeofenceList from './Geofence/GeofenceList'

const customCluster = (cluster) => {
  return L.divIcon({
    className: 'marker-cluster-custom',
    html: ReactDOMServer.renderToString(
      <Avatar
        style={{
          width: 40,
          height: 40,
          fontSize: 20,
          backgroundColor: colors.marker.default,
          color: colors.marker.text,
          borderRadius: '50%',
          textAlign: 'center',
          paddingTop: 4,
        }}
      >
        {cluster.getChildCount()}
      </Avatar>
    ),
  })
}

const Map = ({isWithFullscreen, handleFullscreenClick, coordinates, selectedCenter, zones, isGeofenceLoading}) => {
  const classes = useStyles()
  const [map, setMap] = useState(null)

  const center = coordinates.reduce(
    (acc, curr) => [acc[0] + curr.coordinate[0] / coordinates.length, acc[1] + curr.coordinate[1] / coordinates.length],
    [0, 0]
  )

  const minimalZoom = 2
  const maximalZoom = 20

  const [ selectedMapObject, setSelectedMapObject ] = useState(mapOptionList[0])
  const [ zoom, setZoom ] = useState(5) 

  const handleCenterChange = useCallback((newCenter = selectedCenter) => {
    if (map && newCenter) {
      map.fitBounds([newCenter])
    }
  }, [map, selectedCenter])

  useEffect(() => {
    handleCenterChange()
  }, [handleCenterChange])

  return (
    <>
      <MapContainer 
        center={center}
        zoom={3}
        className={classes.mapContainer}
        zoomControl={false}
        whenCreated={(mapObject) => setMap(mapObject)}
      >
        <TileLayer
          url={selectedMapObject.url}
          subdomains={['mt1', 'mt2', 'mt3']}
          minZoom={minimalZoom}
          maxZoom={maximalZoom}
          key={selectedMapObject.name}
        />

        {/* MARKERS */}
        <MarkerClusterGroup iconCreateFunction={customCluster}>
          {coordinates.map((item, index) => (
            item.coordinate[0] && item.coordinate[1] &&
            <CustomMarker
              onClick={handleCenterChange}
              key={index}
              item={item}
            />
          ))}
        </MarkerClusterGroup>

        {/* MAP CHILDREN ZOOM */}
        <MapChildrenZoom 
          zoom={zoom}
          setZoom={setZoom}
        />
        {/* GEOFENCE LIST */}
        <GeofenceList 
          map={map}
          selectedGeofenceList={zones}
          hasGeofenceListFinishedLoading={isGeofenceLoading}
        />
      </MapContainer>

      {/* FULLSCREEN BUTTON */}
      {isWithFullscreen && 
        <ButtonFullscreen
          onClick={handleFullscreenClick}
        />
      }

      {/* ZOOM PANEL */}
      <PanelZoom
        zoom={zoom}
        setZoom={setZoom}
      />

      {/* MENU PANEL */}
      <PanelChangeMap
        mapOptionList={mapOptionList}
        selectedMapObject={selectedMapObject}
        setSelectedMapObject={setSelectedMapObject}
      />
    </>
  )
}

export default Map
