// MUIS
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

// MUI ICONS
import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'


const GroupLabel = ({style, classes, data, handleGroupClick, handleGroupCheckboxClick}) => {
  return(
    <ListItemButton
      sx={{
        ...style, 
        padding: '12px',
        paddingLeft: '3px',
        height: '48px', 
        '&:hover': {
          backgroundColor: !data.isChecked ? 'rgba(0, 0, 0, 0.04)' : 'rgba(25, 118, 210, 0.12)'
        },
        backgroundColor: data.isChecked ? 'rgba(25, 118, 210, 0.08)' : '',
        cursor: 'pointer',
        borderLeft: `4px solid #${data.color.replace(/[#]/gi, '')}`
      }} 
      onClick={() => handleGroupClick(data.groupId)}>
      <Stack
        direction='row'
        alignItems='center'
        onClick={() => {}}
        className={classes.cellGroup}
            
      >
        <ListItemIcon sx={{minWidth: 0}}>
          <Checkbox checked={data.isChecked} indeterminate={data.isIndeterminate} onClick={(e) => {
            e.stopPropagation()
            handleGroupCheckboxClick(data.groupId)
          }}  />
        </ListItemIcon>
        {/* TEXT */}
        <ListItemText 
          align='left' 
          variant='inherit' 
          className={classes.cellGroupText} 
          primaryTypographyProps={{style: {fontWeight: 600}}}>
          {data.title} ({data.activeChildCount})
        </ListItemText>

        {/* EXPAND/COLLAPSE ICON */}
        {data.expanded ? (
          <IconExpandLess />
        ) : (
          <IconExpandMore />
        )}
      </Stack>
    </ListItemButton>
  )
}

export default GroupLabel