// MUI STYLES
import { makeStyles } from '@mui/styles'

const useSettingsChecklistStyles = makeStyles((theme) => ({
  listIcon: {
    minWidth: 0,
  },
  listItemText: {
    margin: 0,
  },
  listItem: {
    margin: 0,
    padding: '0px',
    height: '35px',
    cursor: 'pointer'
  },
  tooltip: {
    color: '#B0B0B0',
    height: '20px',
    width: '20px'
  },
  divider: {
    cursor: 'pointer',
    marginBottom: '8px'
  }
}))

export default useSettingsChecklistStyles