// MUI STYLES
import { makeStyles } from '@mui/styles'

const useTimeSliderStyles = makeStyles((theme) => ({
  textColor: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  overrideTextButton: {
    textTransform: 'none',
  }
}))

export default useTimeSliderStyles