import { Stack, Typography, Slider, Button } from '@mui/material'
import useTimeSliderStyles from './useTimeSliderStyles'
import { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { useReportPageContext } from 'contexts/ReportPageContext'

const getTimeFromMins = (mins) => {
  if (mins >= 24 * 60 || mins < 0) {
    throw new RangeError('Valid input should be greater than or equal to 0 and less than 1440.')
  }
  var h = mins / 60 | 0,
    m = mins % 60 | 0
  return moment.utc().hours(h).minutes(m).format('hh:mm A')
}

const getTimeOnly = (mins) => {
  if (mins >= 24 * 60 || mins < 0) {
    throw new RangeError('Valid input should be greater than or equal to 0 and less than 1440.')
  }
  var h = mins / 60 | 0,
    m = mins % 60 | 0
  return moment.utc().hours(h).minutes(m).format('HH:mm')
}

const maxMinutes = 1439

const TimeSlider = () => {
  const classes = useTimeSliderStyles()
  const [selectedTime, setSelectedTime] = useState([0, 100])
  const [calculatedTime, setCalculatedTime] = useState([0,maxMinutes])
  const {setTimeFilter} = useReportPageContext()

  const handleChange = (event, newValue) => {
    setCalculatedTime([Math.floor(newValue[0]/100*maxMinutes), Math.floor(newValue[1]/100*maxMinutes)])
    setSelectedTime(newValue)
  }

  const handleButtonClick = (type) => {
    if (type === 'always') {
      setCalculatedTime([Math.floor(0/100*maxMinutes), Math.floor(100/100*maxMinutes)])
      setSelectedTime([0,100])
    }
    if (type === 'day') {
      setCalculatedTime([540, 1080])
      setSelectedTime([Math.floor(540/maxMinutes*100), Math.floor(1080/maxMinutes*100)])
    }
    if (type === 'night') {
      setCalculatedTime([0, 360])
      setSelectedTime([0, Math.floor(360/maxMinutes*100)])
    }
  }

  const firstTimeText = useMemo(() => getTimeFromMins(calculatedTime[0]), [calculatedTime])
  const secondTimeText = useMemo(() => getTimeFromMins(calculatedTime[1]), [calculatedTime])

  useEffect(() => {
    setTimeFilter({
      from: getTimeOnly(calculatedTime[0]),
      to: getTimeOnly(calculatedTime[1])
    })
  }, [calculatedTime, setTimeFilter])


  return(
    <Stack>
      <Typography variant='body2' className={classes.textColor}>
        Control Time: From {firstTimeText} to {secondTimeText}
      </Typography>
      <Stack sx={{paddingLeft: '8px', paddingRight: '8px'}}>
        <Slider
          value={selectedTime}
          onChange={handleChange}
          valueLabelDisplay='off'
        />
      </Stack>
      <Stack direction='row' alignItems='center' justifyContent='center'>
        <Button variant='text' className={classes.overrideTextButton} onClick={() => handleButtonClick('day')}>Day</Button>
        <Button variant='text' className={classes.overrideTextButton} onClick={() => handleButtonClick('night')}>Night</Button>
        <Button variant='text' className={classes.overrideTextButton} onClick={() => handleButtonClick('always')}>Always</Button>
      </Stack>
    </Stack>
  )
}

export default TimeSlider