import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

// LEAFLET
import { 
  useMap, 
  useMapEvents,
} from 'react-leaflet'

const MapChildrenZoom = (props) => {
  const { 
    zoom, 
    setZoom,
  } = props

  const map = useMap()
  
  // UPDATE ZOOM FROM MAP (DON'T DELETE THIS UNUSED CODE, IT'S WORKING)
  const mapEvents = useMapEvents({
    zoomend(event) {
      setZoom(event.sourceTarget._zoom)
    },
  })

  useEffect(() => {
    map.setZoom(zoom)
  }, [zoom])

  return (<></>)
}

MapChildrenZoom.defaultProps = {
  zoom: 5,
}

MapChildrenZoom.propTypes = {
  zoom: PropTypes.number.isRequired, 
  setZoom: PropTypes.func.isRequired,
}

export default MapChildrenZoom