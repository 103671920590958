// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  reportContainer: {
    display: 'flex',
    height: '100%',
  },
  reportSection: {
    height: '100%',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

export default useStyles