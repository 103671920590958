import { useCallback, useEffect, useState, useContext } from 'react'

// COMPONENTS
import CustomList from './CustomList/CustomList'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// HOOKS
import useAxiosPrivate from 'hooks/useAxiosPrivate'

// MUI ICON MATERIALS
import IconNearMe from '@mui/icons-material/NearMe'

// MUIS
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// STYLES
import useStyles from './appUseStyles'

import { getCombinedTrackerData } from 'utilities/tracker'
import { readUserProfileFromLocalStorage } from 'utilities/localStorage'

const PanelObject = ({setSelectedItems}) => {
  const { setIsLoading } = useContext(AllPagesContext)
  const classes = useStyles()
  const theme = useTheme()
  const { hash } = readUserProfileFromLocalStorage()
  const [data, setData] = useState([])
  const [groups, setGroups] = useState([])

  const axiosPrivate = useAxiosPrivate()

  const processRawData = useCallback((rawData) => {
    const fetchedGroup = []
    rawData.forEach(g => {
      const idx = fetchedGroup.findIndex(v => v?.id === g?.group?.id)
      if (idx < 0 && g?.group?.id !== undefined) {
        fetchedGroup.push(g.group)
      }
    })
    setGroups(fetchedGroup)
    setData(rawData)
  }, [])

  const fetchVehicleList = useCallback(async (abortController, isMounted) => {
    const result = await getCombinedTrackerData(abortController.signal, { hash }, false, theme.palette.primary.main, axiosPrivate, setIsLoading)
    if(isMounted) {
      processRawData(result)
    }

  }, [hash, theme.palette.primary.main, processRawData])

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    fetchVehicleList(abortController, isMounted)

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [fetchVehicleList])

  
  return (
    <Stack height='100%' className={`${classes.panelContainer} ${classes.absolute}`}>
      <Stack className={`${classes.headerStyle} zoom`} direction='row'>
        <Stack alignItems='center' justifyContent='center'>
          <IconNearMe className={classes.textWhite} fontSize='small' />
        </Stack>
        <Typography className={classes.titleHeaderStyle}>Objects</Typography>
      </Stack>
      {/* OBJECTS DATA GRID */}
      <Stack className={`${classes.root} zoom`} sx={{overflow: 'hidden'}}>
        <CustomList onItemsSelected={setSelectedItems} groupList={groups} initialObjectList={data} />
      </Stack>
    </Stack>
  )
}

export default PanelObject