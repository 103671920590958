// MUI STYLES
import { makeStyles } from '@mui/styles'

const useDaySelectorStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '12px',
  },
  buttonGroup: {
    marginTop: '6px',
    color: 'blue',
    '& #active': {
      backgroundColor: theme.palette.primary.main,
      border: '1px solid rgba(0, 0, 0, 0.23)',
      color: 'white',
    },
    '& button': {
      textTransform: 'none',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      color: theme.palette.text.primary
    }
  },
  textColor: {
    color: 'rgba(0, 0, 0, 0.54)'
  }
}))

export default useDaySelectorStyles