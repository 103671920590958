import { useEffect, useContext } from 'react'

// APIS
import { axiosPrivate } from 'apis/axios'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// UTILS
import { signOutUser } from 'utilities/authentication'

const useAxiosPrivate = () => {

  const { auth, setAuth } = useContext(AllPagesContext)

  useEffect(() => {
    const requestIntercept = axiosPrivate?.interceptors?.request?.use(
      config => {
        // if (!config?.headers['Authorization']) config.headers['Authorization'] = `Bearer ${auth?.accessToken}`

        return config
      },
      (error) => Promise.reject(error)
    )

    const responseIntercept = axiosPrivate?.interceptors?.response?.use(
      response => response,
      async (error) => {
        const previousRequest = error?.config

        if (error?.response?.status === 400 && error?.response?.data?.status?.description ===  'Wrong hash') {
          previousRequest.sent = true          
          signOutUser(setAuth)

          return axiosPrivate(previousRequest)
        }
        
        return Promise.reject(error)
      }
    )

    return () => {
      axiosPrivate?.interceptors?.request?.eject(requestIntercept)
      axiosPrivate?.interceptors?.request?.eject(responseIntercept)
    }
  }, [auth, setAuth])

  return axiosPrivate
}

export default useAxiosPrivate