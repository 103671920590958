import PropTypes from 'prop-types'

//MUI
import { Backdrop, Box, Stack, Typography } from '@mui/material'

import useStyles from './useGlobalLoadingStyles'


const GlobalLoading = ({isLoading}) => {
  const classes = useStyles()

  return(
    <Backdrop 
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
      open={isLoading}
      onClick={() => {}}>
      <Box sx={{backgroundColor: 'white', width: '119px', height: '62px', borderRadius: '4px'}}>
        <Stack direction='column' alignItems='center' justifyContent='center' height='100%'>
          <div className={classes.dots7} />
          <Typography paddingBottom='6px' color='#11141E' variant='body2'>
            Please Wait
          </Typography>
        </Stack>
      </Box>
    </Backdrop>
  )
}

GlobalLoading.propTypes= {
  isLoading: PropTypes.bool.isRequired
}

export default GlobalLoading