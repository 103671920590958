import { Stack } from '@mui/material'

import DaySelector from '../DaySelector'
import SettingsChecklist from '../SettingsChecklist'
import TimeSlider from '../TimeSlider'

const PluginFilter = () => {

  return(
    <Stack sx={{
      marginTop: '12px',
      paddingLeft: '18px',
      paddingRight: '18px'
    }}>
      <DaySelector />
      <TimeSlider />
      <SettingsChecklist />
    </Stack>
  )
}

export default PluginFilter