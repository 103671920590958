// MUI STYLES
import { makeStyles } from '@mui/styles'

const usePanelStyles = makeStyles((theme) => ({
  selectDrop: {
    '& .MuiMenu-list': {
      maxHeight: '500px'
    },
  },
  dateAndTimeSelect: {
    flex: 1,
    marginRight: 12,
    '& .MuiSelect-select': {
      color: theme.palette.text.primary,
      fontWeight: 600,
      fontSize: 14
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  startAndEndDivider: {
    width: 12,
    height: 2,
    marginRight: 12,
    backgroundColor: theme.palette.action.active
  },
  countDays: {
    marginRight: 'auto',
    fontSize: 12,
    color: theme.palette.text.secondary
  },
  buttonText: {
    fontSize: '16px',
    textTransform: 'none',
    marginRight: '12px',
  },
  buttonCancelText: {
    fontSize: '16px',
    textTransform: 'none',
    color: '#6F7687',
    marginRight: '62px',
  },
  leftPanelItemText: {
    borderLeft: '4px solid transparent',
  },
  datePicker: {
    '& .MuiDateRangePickerDay-day': {
      borderRadius: '0px!important',
    },
    '& .MuiDateRangePickerDay-rangeIntervalDayHighlight': {
      borderRadius: '0px!important',
    }
  },
  leftPanelItemDivider: {
    width: '150px', 
    marginRight: '22px', 
    marginLeft: '22px'
  },
  modalHeader: {
    padding: '22px', 
    borderBottom: 'solid', 
    borderBottomWidth: '1px', 
    borderBottomColor: '#EFEFEF'
  },
  modalFooter: {
    marginTop: '12px', 
    marginBottom: '9px'
  },
  leftPanelItemButtonActive: {
    borderLeftColor: theme.palette.primary.main
  },
  dateAndTimeInput: {
    marginRight: 12,
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 14,
    flex: 1
  },
}))

export default usePanelStyles