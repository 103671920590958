import { Routes, Route } from 'react-router-dom'
import { useEffect, useContext, Suspense } from 'react'
import { AllPagesContext } from 'contexts/AllPagesContext'
// COMPONENTS
import AuthenticationRoute from 'components/Routes/AuthenticationRoute'
import GlobalLoading from 'components/GlobalLoading'
import PrivateRoute from 'components/Routes/PrivateRoute'
import SnackBar from 'components/SnackBar'

// ROUTES
import routes from 'routes/routes'

// UTILTITIES
import { getFavIconLogo } from 'utilities/logo'

const App = () => {
  const { snackbarObject, showSnackbar, isLoading } = useContext(AllPagesContext)

  const getRouteComponent = (inputItem) => {
    if (inputItem.routeType === 'authentication') {
      return (
        <AuthenticationRoute>
          {inputItem.element}
        </AuthenticationRoute>
      )
    }
    else if (inputItem.routeType === 'private') {
      return (
        <PrivateRoute>
          {inputItem.element}
        </PrivateRoute>
      )
    }
  }

  // UPDATE FAVICON AND APP TITLE
  useEffect(() => {
    const faviconElement = document.getElementById('favicon')
    faviconElement.href = getFavIconLogo()

    if (process.env.REACT_APP_DASHBOARD_TYPE === 'LACAK') document.title = 'Lacak'
    else if (process.env.REACT_APP_DASHBOARD_TYPE === 'GMM') document.title = 'GMM Teletech'
  }, [])

  return (
    <Suspense fallback={<div/>}>
      <Routes>
        {routes.map((item, index) => (
          <Route
            key={index}
            path={item.path}
            element={getRouteComponent(item)}
          />
        ))}
      </Routes>
      <SnackBar
        open={snackbarObject.open}
        setToast={showSnackbar}
        severity={snackbarObject.severity}
        title={snackbarObject.title}
        message={snackbarObject.message}
      />
      <GlobalLoading isLoading={isLoading} />
    </Suspense>
  )
}

export default App