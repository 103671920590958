//REACT
import {useContext, useState} from 'react'
import {Link} from 'react-router-dom'

//CONSTANT
import { colors } from 'constants/colors'

//CONTEXT
import { AllPagesContext } from 'contexts/AllPagesContext'

//MUI MATERIAL
import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'

//MUI ICONS MATERIAL
import Vehicles from '@mui/icons-material/DirectionsCar'
import Logout from '@mui/icons-material/Logout'
import MenuOpen from '@mui/icons-material/MenuOpen'

//STYLES
import useStyles from './drawerUseStyles'

//COMPONENTS
import NavigationTooltip from './NavigationTooltip'

// UTILITIES
import { getDrawerStateFromLocalStorage, setDrawerStateToLocalStorage } from 'utilities/localStorage'
import { removeUserProfileFromLocalStorage } from 'utilities/localStorage'
import { getDashboardLogo } from 'utilities/logo'

const drawerWidth = 256

const openedMixin = (theme) => ({
  width: drawerWidth,
  background: colors.drawer.default,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  background: colors.drawer.default,
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: open === true ? drawerWidth : '65px',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
)

export default function MiniDrawer() {
  const { setAuth } = useContext(AllPagesContext)
  const classes = useStyles()
  const [open, setOpen] = useState(getDrawerStateFromLocalStorage() === 'true' ? true : false)

  const handleDrawerOpen = () => {
    setOpen(true)
    setDrawerStateToLocalStorage(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
    setDrawerStateToLocalStorage(false)
  }

  const isNavigationActive = (inputPath) => {
    if (inputPath === '/' && window.location.pathname === '/') return true
    else if (inputPath !== '/' && window.location.pathname.includes(inputPath)) return true
    else return false
  }

  const getListItemIconClassName = (inputPath) => {
    return isNavigationActive(inputPath)
      ? classes.navigationItemContentActive
      : classes.navigationItemContentInactive
  }

  const getListItemClassName = (inputPath) => {
    return isNavigationActive(inputPath) ? classes.navigationListItemContentActive : ''
  }

  const signOut = () => {
    removeUserProfileFromLocalStorage()
    setAuth({})
  }

  const items = [
    // {name: 'Reports', icon: <Reports/>, path: '/'},
    {name: 'Vehicles', icon: <Vehicles/>, path: '/'},
    {name: 'Logout', icon: <Logout/>, path: '/sign-in'}
  ]

  return (
    <Box className={`${classes.drawerContainer} zoom`}>
      <Drawer variant='permanent' open={open} classes={{ paper: classes.borderNone }} className='no-zoom'>
        <DrawerHeader>
          { open === true ?
            <>
              <IconButton onClick={handleDrawerClose} sx={{
                position: 'absolute',
                left: 0,
                marginLeft: '8px'
              }}>
                <MenuOpen className={classes.textWhite}/>
              </IconButton>
              <Link to={'/'}>
                <Stack
                  flex={1}
                  className={classes.alignItemCenter}>
                  <Stack
                    component='img'
                    height={28}
                    alt='Lacak io.'
                    src={getDashboardLogo()}
                  />
                </Stack>
              </Link>
            </>
            :
            <IconButton onClick={handleDrawerOpen} className={classes.p0}>
              <MenuOpen className={classes.menuClose} />
            </IconButton>
          }
        </DrawerHeader>
        <List>
          {items.map((item, index) => (
            <NavigationTooltip
              placement='right'
              sx={{ display: open ? 'none' : 'block' }}
              key={index}
              selected={isNavigationActive(item.path)}
              title={
                <Link to={item.path} className={classes.textDecorationNone} onClick={item.path === '/sign-in' ? signOut : null}>
                  <ListItemButton
                    className={getListItemClassName(item.path)}
                  >
                    <ListItemText className={`${getListItemIconClassName(item.path)} no-zoom`}>
                      {item.name}
                    </ListItemText>
                  </ListItemButton>
                </Link>
              }
            >
              <Link key={index} to={item.path} className={classes.textDecorationNone} onClick={item.path === '/sign-in' ? signOut : null}>
                <ListItem disablePadding className={`${getListItemClassName(item.path)} ${classes.displayBlock}`}>
                  <ListItemButton
                    className={classes.listItemButton}
                    sx={{justifyContent: open ? 'initial' : 'center'}}>
                    <ListItemIcon
                      className={`${getListItemIconClassName(item.path)} ${classes.listItemIcon}`}
                      sx={{
                        mr: open ? 2 : 'auto',
                        position: 'absolute'
                      }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{fontSize: '14px'}}
                      className={getListItemIconClassName(item.path)} primary={item.name} sx={{ fontSize: '14px', opacity: open ? 1 : 0, marginLeft: 8 }} />
                  </ListItemButton>
                </ListItem>
              </Link>
            </NavigationTooltip>
          ))}
        </List>
      </Drawer>
    </Box>
  )
}