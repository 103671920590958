import React from 'react'
import PropTypes from 'prop-types'

// MUIS
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

const VehicleDropdown = ({label, value, handleChange, options, ...otherProps}) => {
  return (
    <Stack
      direction='row'
      alignItems={'center'}
      margin={2}
      sx={{ m: 2, width: 200, minWidth: 400 }}>
      <Typography
        variant='body1'
        className='marginRightAuto'
      >
        {label}
      </Typography>
      <FormControl variant='standard' sx={{minWidth: '70%', marginLeft: 1, p: 1}} size='medium' {...otherProps}>
        <Select
          value={value}
          onChange={handleChange}
          label={label}
        >
          <MenuItem value={-1}>
            <em>None</em>
          </MenuItem>
          {
            options.map((option, key) => 
              <MenuItem value={option.value} key={key}>{option.label}</MenuItem>
            )
          }
        </Select>
      </FormControl>
    </Stack>
  )
}

VehicleDropdown.defaultProps = {
  label: '',
  options: [],
}

VehicleDropdown.propTypes = {
  label: PropTypes.string, 
  value: PropTypes.number.isRequired, 
  handleChange: PropTypes.func.isRequired, 
  options: PropTypes.array,
}

export default VehicleDropdown
