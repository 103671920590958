// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.shadows,
    border: '0.5px solid lightgrey',
    height: '100%',
    width: '372px',
  },
  titleHeaderStyle: {
    margin: 0,
    fontSize: '20px',
    fontWeight: 600,
    color: 'white',
    display: 'flex',
    justifyContent: 'start'
  },
  headerStyle: {
    backgroundColor: 'black',
    padding: '15px',
    borderBottom: '0.5px solid lightgrey',
  },
  textWhite: {
    color: 'white'
  },
  cellGroup: {
    width: '100%',
    height: '100%'
  },
  cellGroupText: {
    width: '100%',
    fontWeight: 600,
    paddingLeft: '8px',
    fontSize: '14px',
  },
  cellGroupIcon: {
    marginRight: 18
  },
  cellItemContainer: {
    width: '100%',
    paddingRight: 24
  },
  cellItemIconStatus: {
    height: 12,
    width: 12
  },
  cellItemLabel: {
    flex: 1,
    paddingRight: 8,
    paddingLeft: '8px',
    fontSize: '14px',
  },
  cellItemSpeed: {
    width: 60,
    textAlign: 'right',
    marginRight: 10
  },
  // moreMenuItem: {
  //   paddingTop: 0,
  //   paddingBottom: 0,
  // },
  // moreMenuItemIcon: {
  //   marginLeft: -8,
  // },
  headerRoot: {
    width: '100%',
    paddingRight: 10
  },
  headerInput: {
    '& .MuiInputLabel-root': {
      fontSize: 16
    },
    '& .MuiInputBase-input': {
      fontSize: 16
    }
  },
  absolute: {
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 10,
    backgroundColor: 'white',
  },
  listRoot: {
    padding: '6.2px',
    paddingRight: '2px',
    paddingLeft: '6px',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.08)'
  },
  checkboxMargin: {
    marginLeft: 0
  },
}))

export default useStyles
