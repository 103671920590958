//STYLES
import panelCreateReportUseStyle from './panelCreateReportUseStyle'

//MUI ICON MATERIALS
import IconArrowBack from '@mui/icons-material/ArrowBack'

//MUI MATERIALS
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import IconButton from '@mui/material/IconButton'
import { useReportPageContext } from 'contexts/ReportPageContext'

const PanelCreateReports = (props) => {
  const { setIsCreatingReport, setPanelObj } = props
  const { setReportType, reportType } = useReportPageContext()
  const classes = panelCreateReportUseStyle()
  const optionList = [
    { name: 'Trips', description: 'Detailed Trip History', value: 'trips' },
    { name: 'Stops', description: 'Detailed Stop History', value: 'stops' },
    { name: 'Trips and stops by shifts', description: 'Breakdown on trips and stops by shifts', value: 'tripsAndStops' },
  ]

  return (
    <Stack className={`${classes.panelContainer} zoom ${classes.absolute}`}>
      <Stack className={classes.headerStyle} direction='row'>
        <IconButton size='small' onClick={() => {
          setIsCreatingReport(curr => !curr)
          setPanelObj(false)
          setReportType('')
        }
        }>
          <IconArrowBack className={classes.textWhite} fontSize='small' />
        </IconButton>
        <Typography className={classes.titleHeaderStyle}>Available reports</Typography>
      </Stack>
      <List disablePadding sx={{ height: '100%' }}>
        <ListItemButton className={classes.primaryButtonStyle}>
          <Typography className='boldText'>Activity</Typography>
        </ListItemButton>
        {optionList.map((dummy, index) => (
          <ListItemButton
            onClick={() => {
              setPanelObj(true)
              setReportType(dummy.value)
            }}
            className={reportType === dummy.value ? classes.selectedType : classes.itemStyle}
            key={index}>
            <ListItemText
              classes={{ primary: 'boldText' }}
              className={classes.itemTitleStyle} primary={dummy.name}/>
            <ListItemText className={classes.itemDescStyle} primary={dummy.description}/>
          </ListItemButton>
        ))}
      </List>
    </Stack>
  )
}

export default PanelCreateReports