import { useCallback, useMemo } from 'react'

import { Stack, Typography, Button, ButtonGroup } from '@mui/material'

import useDaySelectorStyles from './useDaySelectorStyles'
import { useReportPageContext } from 'contexts/ReportPageContext'




const DaySelector = () => {
  const classes = useDaySelectorStyles()
  const {selectedDay, setSelectedDay} = useReportPageContext()

  const handleDayClick = useCallback((id) => {
    const newSelected = [...selectedDay]
    const idx = newSelected.findIndex(v => v === id)
    if (idx > -1) {
      newSelected.splice(idx, 1)
    } else {
      newSelected.push(id)
    }
    setSelectedDay(newSelected)
  }, [selectedDay, setSelectedDay])

  const buttons = useMemo(() => ([
    <Button key='mo' onClick={() => handleDayClick(1)}  id={selectedDay.includes(1) ? 'active': ''}>Mo</Button>,
    <Button key='tu' onClick={() => handleDayClick(2)} id={selectedDay.includes(2) ? 'active': ''}>Tu</Button>,
    <Button key='we' onClick={() => handleDayClick(3)}  id={selectedDay.includes(3) ? 'active': ''}>We</Button>,
    <Button key='th' onClick={() => handleDayClick(4)}  id={selectedDay.includes(4) ? 'active': ''}>Th</Button>,
    <Button key='fr' onClick={() => handleDayClick(5)}  id={selectedDay.includes(5) ? 'active': ''}>Fr</Button>,
    <Button key='sa' onClick={() => handleDayClick(6)}  id={selectedDay.includes(6) ? 'active': ''}>Sa</Button>,
    <Button key='su' onClick={() => handleDayClick(7)}  id={selectedDay.includes(7) ? 'active': ''}>Su</Button>,
  ]), [handleDayClick, selectedDay]) 
  return(
    <Stack className={classes.root}>
      <Typography variant='body2' className={classes.textColor}>
        Days
      </Typography>
      <ButtonGroup className={classes.buttonGroup} size='medium' aria-label='small button group'>
        {buttons}
      </ButtonGroup>
    </Stack>
  )
}

export default DaySelector