import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import React, { useCallback } from 'react'
import AlertTitle from '@mui/material/AlertTitle'
import Typography from '@mui/material/Typography'

const SnackBarCustom = ({open, setToast, severity, title, message}) => {

  const handleToastClose = useCallback(() => {
    setToast('reset')
  }, [setToast])

  return(
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleToastClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MuiAlert elevation={6} variant='filled' onClose={handleToastClose} severity={severity} sx={{ width: '100%' }}>
          {title && <AlertTitle sx={{fontWeight: 700}}>{title}</AlertTitle>}
          {message && <Typography 
            variant='body2'
          >
            {message}
          </Typography>}
        </MuiAlert>
      </Snackbar>
    </>
  )
}

export default SnackBarCustom