// MUI STYLES
import { makeStyles } from '@mui/styles'

const useMultiShiftStyles = makeStyles((theme) => ({
  textColor: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  shiftRoot: {
    marginTop: '12px'
  },
  textField: {
    width: '100%',
    '& .Mui-disabled:before': {
      borderBottomStyle: 'solid!important',
      borderColor: '#EFEFEF'
    },
    '& .MuiInput-input': {
      color: 'black',
      '-webkit-text-fill-color': 'unset!important',
      paddingTop: 0
    }
  },
  dateAndTimeSelect: {
    flex: 1,
    marginRight: 12,
    '& .MuiSelect-select': {
      color: theme.palette.text.primary,
      fontWeight: 400,
      fontSize: 14
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  inputLabel: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 14
  },
  startAndEndDivider: {
    width: 12,
    height: 2,
    marginRight: 12,
    backgroundColor: theme.palette.action.active
  },
  timeWrapper: {
    marginTop: '12px'
  },
  overrideTextButton: {
    textTransform: 'none',
    maxWidth: '80px',
    marginTop: '16px',
    paddingLeft: 0
  }
}))

export default useMultiShiftStyles