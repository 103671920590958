// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    height: '600px',
    width: '1200px',
    padding: 0,
  },
  closeButton: {
    alignSelf: 'flex-end',
    margin: '8px 8px 8px 0'
  },
  iconStatus: {
    height: 16,
    width: 16,
    borderRadius: '50%',
    padding: 2,
    color: 'white',
  },
  tableContainer: {
    width: '450px',
  },
  vehicleFlyout: {
    height: '100%',
    padding: 0,
  },
}))

export default useStyles