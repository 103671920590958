// CONSTANT
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    display: 'flex',
  },
  borderNone: {
    border: 'none',
    backgroundColor: colors.drawer.default,
  },
  textDecorationNone: {
    textDecoration: 'none'
  },
  displayBlock: {
    display: 'block'
  },
  menuClose:{
    color: 'white',
    transform: 'rotateY(180deg)'
  },
  p0: {
    padding: '0'
  },
  textWhite: {
    color: 'white'
  },
  alignItemCenter: {
    alignItems: 'center'
  },
  navigationItemContentInactive: {
    color: '#B0B0B0',
  },
  navigationItemContentActive: {
    color: 'white',
  },
  navigationListItemContentActive: {
    backgroundColor: theme.palette.primary.main,
  },
  listItemButton: {
    maxHeight: 42
  },
  listItemIcon: {
    minWidth: 0,
    justifyContent: 'center'
  }
}))

export default useStyles