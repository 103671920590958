// MUI STYLES
import { makeStyles } from '@mui/styles'

const usePanelStyles = makeStyles((theme) => ({
  panelContainer: {
    border: '0.5px solid lightgrey',
  },
  root: {
    boxShadow: theme.shadows,
    border: '0.5px solid lightgrey',
    height: '100%',
    width: '372px',
    overflow: 'auto',
  },
  absolute: {
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 10,
    backgroundColor: 'white',
  },
  headerStyle: {
    backgroundColor: 'black',
    padding: '15px',
    paddingLeft: '19px',
    borderBottom: '0.5px solid lightgrey',
  },
  textWhite: {
    color: 'white'
  },
  titleHeaderStyle: {
    margin: 0,
    marginLeft: '15px',
    fontSize: '20px',
    fontWeight: 600,
    color: 'white',
    display: 'flex',
    justifyContent: 'start'
  },
  bottomButtonWrapper: {
    height: '54px', 
    padding: '12px',
    paddingRight: '2px',
    paddingLeft: '6px',
    boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)'
  },
  buttonCancelText: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.54)',
    textTransform: 'none'
  },
  buttonOkText: {
    fontSize: '14px',
    textTransform: 'none'
  },
  inputWrapper: {
    marginTop: '12px', 
    marginRight: '18px' 
  },
  iconStyle: { 
    marginRight: '14px', 
    marginLeft: '20px', 
    color: 'rgba(0, 0, 0, 0.54)' 
  },
  textField: {
    width: '100%',
    '& .Mui-disabled:before': {
      borderBottomStyle: 'solid!important',
      borderColor: '#EFEFEF'
    },
    '& .MuiInput-input': {
      color: 'black',
      '-webkit-text-fill-color': 'unset!important',
      paddingTop: 0
    }
  },
  
}))

export default usePanelStyles