import React from 'react'
import ReactDOMServer from 'react-dom/server'

// LEAFLET
import { Marker } from 'react-leaflet'
import L from 'leaflet'

// MUIS
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './customMarkerUseStyles'

const CustomMarker = (props) => {
  const { 
    item,
    onClick
  } = props

  const classes = useStyles()

  const MarkerComponent = () => {
    return (
      <Box className={classes.markerContainer}>
        {/* ICON */}
        <Avatar 
          className={classes.markerParking}
          style={{backgroundColor: `#${item.color?.replace(/[#]/gi, '')}`}}
        >
          P
        </Avatar>
  
        {/* LABEL */}
        <Typography 
          variant='caption'
          className={classes.markerLabel}
          style={{backgroundColor: `#${item.color?.replace(/[#]/gi, '')}`}}
          display='inline'
        >
          {item.label}
        </Typography>
      </Box>
    )
    
  }

  return (
    <Marker
      position={[ item.coordinate[0], item.coordinate[1] ]}
      icon={L.divIcon({
        className: 'custom-icon',
        html: ReactDOMServer.renderToString(
          <MarkerComponent/>
        )
      })}
      eventHandlers={{
        click: (e) => {
          onClick(item.coordinate)
        },
      }}
    />
  )
}

export default CustomMarker