//APIS
import axios from 'apis/axios'

export const searchLocation = async (
  inputSignal, 
  inputBodyParams, 
  inputAxios,
) => {
  try {
    const ax = inputAxios ?? axios
    const response = await ax.post(
      'geocoder/search_location', 
      {
        ...inputBodyParams,
      }, 
      { 
        signal: inputSignal,
      },
    )
    
    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    return {
      value: ''
    }
  }
}