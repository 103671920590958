import {
  equals,
  isEmpty,
  reject,
} from 'ramda'

export const isObjectEmpty = (inputObject) => {
  return isEmpty(reject(equals(null), inputObject))
}

export const didSuccessfullyCallTheApi = (inputStatus) => {
  const successRegex = /^20[0-9]$/
  return successRegex.test(inputStatus)
}