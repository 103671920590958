export const values = {
  // ALL PAGES
  fontFamilyOpenSans: [ 'Open Sans', 'monospace', 'sans-serif' ].join(','),
  zoomBoundary: '@media only screen and (max-height: 800px) and (min-width: 1200px)',
  noZoomBoundary: '@media only screen and (min-height: 801px)',

  // DRAWER
  drawerWidth: 256,
  drawerWidthOnMinimized: 72,
  zoomValue: 0.85,
  
  // FLYOUT
  flyoutWidth: 400,
  // SNACKBAR
  initialSnackbarObject: {
    open: false,
    severity: 'success',
    title: '',
    message: '',
  },

}