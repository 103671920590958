// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  panelContainer: {
    boxShadow: theme.shadows,
    border: '0.5px solid lightgrey',
    height: '100%',
    minWidth: '320px',
    width: '100%',
    overflow: 'auto',
    marginLeft: '24px',
  },
  headerStyle: {
    backgroundColor: 'black',
    padding: '15px',
    borderBottom: '0.5px solid lightgrey',
    alignItems: 'center'
  },
  titleHeaderStyle: {
    margin: 0,
    justifyContent: 'center',
    fontSize: '20px',
    fontWeight: 600,
    color: 'white'
  },
  textWhite: {
    color: 'white'
  },
  absolute: {
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 10,
    backgroundColor: 'white',
  },
  chipWrapper: {
    backgroundColor: '#F2F2F2',
    width: '100%',
    minHeight: '40px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  chipStyle: {
    backgroundColor: '#D5F2FF',
    color: theme.palette.text.primary
  },
  chipIconStyle: {
    color: `${theme.palette.text.primary}!important`
  },
  tableHeader: {
    color: '#000',
    fontSize: '12px',
  },
  tableClass: {
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
    },
  }
}))

export default useStyles