// MUIS
import Stack from '@mui/material/Stack'
import Checkbox from '@mui/material/Checkbox'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'


const ChildLabel = ({ style,classes, data, handleChildClick, handleChildCheckboxClick}) => {

  return(
    <ListItemButton
      sx={{
        ...style, 
        padding: '12px', 
        paddingLeft: '3px',
        height: '48px',
        backgroundColor: data.isChecked ? 'rgba(25, 118, 210, 0.08)' : '',
        borderLeft: `4px solid #${data.group.color.replace(/[#]/gi, '')}`
      }
      }
      onClick={() => handleChildClick(data.id)}
    >
      <Stack
        direction='row'
        alignItems='center'
        onClick={() => {}}
        className={classes.cellGroup}
      >
        <ListItemIcon sx={{minWidth: 0}}>
          <Checkbox checked={data.isChecked} onClick={(e) => {
            e.stopPropagation()
            handleChildCheckboxClick(data.id)
          }} />
        </ListItemIcon>
    
        {/* LABEL */}
        <ListItemText
          variant='inherit'
          color='text.primary'
          className={classes.cellItemLabel}
        >
          {data.label}
        </ListItemText>
    
      </Stack>
    </ListItemButton>
  )
}

export default ChildLabel