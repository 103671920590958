import { makeStyles } from '@mui/styles'



const useStyles = makeStyles((theme) => ({
  dots7: {
    marginTop: '4px',
    width: '57px',
    aspectRatio: '2',
    background: `no-repeat radial-gradient(circle closest-side,#179FD8 90%,#0000) 0% 50%, 
    no-repeat radial-gradient(circle closest-side,#179FD8 90%,#0000) 35%  50%, 
    no-repeat radial-gradient(circle closest-side,#179FD8 90%,#0000) 68% 50%, 
    no-repeat radial-gradient(circle closest-side,#179FD8 90%,#0000) 100% 50%`,
    backgroundSize: 'calc(100%/5) 100%',
    animation: '$d7 1.6s infinite linear'
  },
  '@keyframes d7': {
    '33%': {
      backgroundSize: 'calc(100%/5) 0% ,calc(100%/5) 100%,calc(100%/5) 100%, calc(100%/5) 100%',
    },
    '50%': {
      backgroundSize: 'calc(100%/5) 100%,calc(100%/5) 0%, calc(100%/5) 100%, calc(100%/5) 100%'
    },
    '66%': {
      backgroundSize: 'calc(100%/5) 100%,calc(100%/5) 100%,calc(100%/5) 0%, calc(100%/5) 100%'
    },
    '80%': {
      backgroundSize: 'calc(100%/5) 100%,calc(100%/5) 100%,calc(100%/5) 100%, calc(100%/5) 0%'
    },
    '100%': {
      backgroundSize: 'calc(100%/5) 100%,calc(100%/5) 100%,calc(100%/5) 100%, calc(100%/5) 100%'
    }
  }
}))

export default useStyles