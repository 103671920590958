// PAGES
import SignIn from 'pages/SignIn/SignIn'
import Reports from 'pages/Report/Reports'
import Vehicle from 'pages/Vehicles/Vehicles'
import { ReportPageContextProvider } from 'contexts/ReportPageContext'

const routes = [
  // AUTHENTICATION
  {
    path: '/sign-in',
    element: <SignIn/>,
    routeType: 'authentication',
  },
  // PRIVATE
  {
    path: '/reports',
    element: 
  <ReportPageContextProvider>
    <Reports/>
  </ReportPageContextProvider>
    ,
    routeType: 'private',
  },
  {
    path: '/',
    element: <Vehicle/>,
    routeType: 'private',
  },
]

export default routes