import React from 'react'

// COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'

// MUI ICONS\
import IconFullscreen from '@mui/icons-material/Fullscreen'

// STYLES
import useStyles from './buttonFullscreenUseStyles'

const ButtonFullscreen = ({onClick}) => {
  const classes = useStyles()

  return (
    <ButtonGroup 
      className={classes.panelContainer}
      variant='contained'
    >
      {/* MAP BUTTON */}
      <CustomTooltipBlack
        title='Fullscreen'
        placement='bottom'
      >
        <Button 
          className={classes.buttonGroupItem}
          variant='text'
          onClick={onClick}
        >
          <IconFullscreen className={classes.icon}/>
        </Button>
      </CustomTooltipBlack>
    </ButtonGroup>
  )
}

export default ButtonFullscreen