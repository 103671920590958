import { createContext, useCallback, useState } from 'react'

// MUIS
import useMediaQuery from '@mui/material/useMediaQuery'

// UTILITIES
import { readUserProfileFromLocalStorage } from 'utilities/localStorage'

import { values } from 'constants/value'

const AllPagesContext = createContext()

const AllPagesContextProvider = (props) => {
  // AUTH
  const [ auth, setAuth ] = useState(readUserProfileFromLocalStorage())

  // BREAKPOINT ZOOM
  const isZoomBoundary = useMediaQuery(values.zoomBoundary)
  const isNoZoomBoundary = useMediaQuery(values.noZoomBoundary)
  // return true if in screen zoom boundary
  const breakpointZoomBoundary = (isZoomBoundary && !isNoZoomBoundary) ? isZoomBoundary : false

  //SNACKBAR
  const [ snackbarObject, setSnackbarObject ] = useState(values.initialSnackbarObject)

  //LOADING
  const [isLoading, setIsLoading] = useState(false)

  const showSnackbar = useCallback((severity, message, title) => {
    if (severity === 'reset') {
      setSnackbarObject(prev => ({
        open: false,
        severity: prev.severity,
        title: prev.title,
        message: prev.message,
      }))
      return
    }
    setSnackbarObject(
      {
        open: true,
        severity: severity,
        title: title,
        message: message ?? '',
      }
    )
  }, [])

  return (
    <AllPagesContext.Provider
      value={{
        // AUTH
        auth, setAuth,
        // ZOOM BOUNDARY
        breakpointZoomBoundary,
        //SNACKBAR
        snackbarObject, showSnackbar,
        //LOADING
        isLoading, setIsLoading,
      }}
    >
      {props.children}
    </AllPagesContext.Provider>
  )
}

export { AllPagesContextProvider, AllPagesContext }